import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Meta extends Component {

    render() {
        let meta = [];

        for(let item of this.props.data) {

            const TagType = item.tag;

            let tag = null;

            if(item.tag === 'meta' && item.attributes.hasOwnProperty('name') && item.attributes.name === 'title') {
                tag = (
                    <title>{item.attributes.content}</title>
                )
            } else {

                tag = (
                    <TagType {...item.attributes} />
                );
            }

            meta.push(tag);
        }

        return (
            <Helmet>
                {meta}
            </Helmet>
        );
    }

}
