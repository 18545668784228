import React, { Component } from 'react';

import './Banner.css';

export default class Banner extends Component {

    render() {
        let pad = '';
        if(this.props.padded) {
            pad = ' padded';
        }

        return (
            <div className={"banner" + pad}>
                <h2>{this.props.text}</h2>
            </div>
        );
    }
}