import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

import './BackToTop.css';

export default class BackToTop extends Component {

    state = {
        isVisible: false,
        viewportHeight: window.innerHeight
    };

    componentDidMount = () => {
        document.addEventListener("scroll", this.handleScroll, { passive: true })
    }

    render() {
        if(this.state.isVisible) {
            return (
                <div onClick={this.handleClick} className={"back-to-top"}>
                    Back to top <FontAwesomeIcon icon={faCaretUp} />
                </div>
            );
        } else {
            return null;
        }
    }

    handleScroll = () => {
        if(!this.state.isVisible && window.scrollY > this.state.viewportHeight) {
            this.setState({
                isVisible: true
            });
        }

        if(this.state.isVisible && window.scrollY < this.state.viewportHeight) {
            this.setState({
                isVisible: false
            })
        }
    }

    handleClick = (e) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
}