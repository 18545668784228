import React, { Component } from 'react';
import Card from "../Card/Card";
import ContentList from "../../layout/ContentList/ContentList";
import CardPlaceholder from "../CardPlaceholder/CardPlaceholder";

export default class SidebarDetails extends Component {

  state = {
    items: []
  };

  componentDidUpdate = (prevProps) => {
    if(prevProps.subjects !== this.props.subjects) {
      this.setState({
        items: []
      });

      this.getRandomFilms();
    }
  }

  componentDidMount = () => {
    this.getRandomFilms();
  }

  render(){
    if(this.state.items.length === 0) {
      return (
          <ContentList>
            <CardPlaceholder />
            <CardPlaceholder />
            <CardPlaceholder />
            <CardPlaceholder />
          </ContentList>
      );
    } else {
      return (
        <div className="content">
          <h4>You might also like</h4>
          <div className="filmList">
              { this.state.items }
          </div>
        </div>
      );
    }
  }

  getRandomFilms = () => {
    let filmCount = 0;

    let options = {
      'page[limit]': 4,
      'filter[subject][condition][path]': 'field_subjects.id',
      'filter[subject][condition][operator]': 'IN',
      'filter[outlet][condition][path]': 'outlets.name',
      'filter[outlet][condition][value]': 'ajffrecommends.org'
    };

    const subjects = this.props.subjects;

    for(const i in subjects) {
      const propName = 'filter[subject][condition][value][' + i + ']';
      options[propName] = subjects[i].id;
    }

    this.props.api.getEntities('node', 'film', options).then(
      async (response) => {
        if (response.data.meta) {
          if (response.data.meta.count > 4) {
            filmCount = this.getRandomInt(response.data.meta.count - 4);
          }
        }
        options['page[offset]'] = filmCount;

        this.props.api.getEntities('node', 'film', options).then(
          (response) => {
            this.processFilmResponse(response);
          }
        );  
  
      }
    );
  }

  processFilmResponse = (response) => {
    const films = response.data.data;
    const includes = response.data.included;
    const renderedFilms = this.renderFilms(films, includes);

    this.setState({
      items: renderedFilms,
    });
  }

  renderFilms = (films, includes) => {
    const renderedFilms = [];

    for(const film of films) {
      let heroImageUrl = '';
      const heroImageMedia = film.relationships.field_hero_image.data;
      if(heroImageMedia instanceof Object && heroImageMedia.hasOwnProperty("id")) {
        const heroImageInclude = this.props.api.lookupInclude(includes, heroImageMedia.id);
        const heroImageFile = this.props.api.lookupInclude(includes, heroImageInclude.relationships.field_media_image.data.id);
        heroImageUrl = heroImageFile.links.large?.href;
      }
      let url = '/node/' + film.attributes.drupal_internal__nid;
      if(film.attributes.path.alias !== "") {
        url = film.attributes.path.alias;
      }
      renderedFilms.push(<Card key={film.id} url={url} image={heroImageUrl} title={film.attributes.title} body={film.attributes.field_synopsis_for_ajffrecommend} small={true} />);
    }

    return renderedFilms;

  }
  
  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

}