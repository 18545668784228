import React, { Component } from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';

import './Header.css';

export default class Header extends Component {

    render() {
        return (
            <header>
                <Navbar expand="lg" className="navbar-dark">
                    <Navbar.Brand href="/" className="headline"><Image src="/images/logo.svg" height="104" width="225" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="headline">
                            <Nav.Link href="/content/about-us">About</Nav.Link>
                            <Nav.Link href="/browse">Browse</Nav.Link>
                            <Nav.Link href="/content/faq">FAQ</Nav.Link>
                            <Nav.Link href="/content/support-us">Support Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
            </header>
        );
    }

}