import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './HeroImage.css';

export default class HeroImage extends Component {

    render() {
        if(this.props.placeholder) {
            return (
                <div className="hero-image placeholder"></div>
            );
        } else {
            if(this.props.image) {
                return (
                    <div className="hero-image">
                        <Image src={this.props.image} />
                    </div>
                );
            }
        }

        return <></>;
    }

}
