import React, { Component } from 'react';
import MetaItem from '../MetaItem/MetaItem';
import { Link } from 'react-router-dom';

import './Meta.css';

export default class Meta extends Component {

    render() {

        let alignmentClass = 'inline';

        if(this.props.placeholder) {
            if(this.props.isMulti) {
                alignmentClass = 'above';
            }

            return (
                <div className={`meta placeholder ${alignmentClass}`}>
                    <div className="meta-label"></div>
                    <div className="meta-items">
                        {this.getMetaItemPlaceholders()}
                    </div>
                </div>
            );

        } else {
            let rawValues = [];
            let values = [];
            let rawText = '';
            let text = '';
            let id = null;
            let i;

            if(this.props.isInclude) {
                if(this.props.isMulti && this.props.entity.data.relationships[this.props.field].data instanceof Array) {
                    for(i in this.props.entity.data.relationships[this.props.field].data) {
                        rawValues.push(this.props.entity.data.relationships[this.props.field].data[i]);
                    }
                } else if(this.props.isMulti === false) {
                    rawValues.push(this.props.entity.data.relationships[this.props.field].data);
                }
            } else {
                rawValues.push(this.props.entity.data.attributes[this.props.field]);
            }

            for(const value of rawValues) {
                if(value instanceof Object && value.hasOwnProperty('id')) {
                    const include = this.props.api.lookupInclude(this.props.entity.included, value.id);
                    const entityInfo = this.getEntityInfo(include);

                    if(include.attributes.hasOwnProperty('name')) {
                        rawText = include.attributes.name;
                    } else {
                        rawText = include.attributes.title;
                    }

                    const url = "/search?type=" + entityInfo.entityType + "&bundle=" + entityInfo.bundle + "&value=" + entityInfo.id + "&label=" + rawText;

                    text = <Link to={url}>{rawText}</Link>;

                    id = value.id

                } else {
                    rawText = value;
                    text = rawText;
                }

                values.push(<MetaItem key={rawText} rawText={rawText} text={text} suffix={this.props.suffix} value={id} />);
            }

            values.sort((a, b) => (a.props.rawText > b.props.rawText) ? 1 : -1);

            if(values.length > 1) {
                alignmentClass = 'above';
            }

            if(values.length > 0) {
                return (
                    <div key={this.props.field} className={`meta ${alignmentClass}`}>
                        <div className="meta-label">{this.props.label}</div>
                        <div className="meta-items">{values}</div>
                    </div>
                );
            }

            return (
                <></>
            );
        }

    }

    getMetaItemPlaceholders = () => {
        if(this.props.isMulti) {
            return [
                <MetaItem key="1" placeholder={true} />,
                <MetaItem key="2" placeholder={true} />,
                <MetaItem key="3" placeholder={true} />,
            ];
        } else {
            return [
                <MetaItem key="1" placeholder={true} />,
            ];
        }
    }

    getEntityInfo = (entity) => {
        const typeParts = entity.type.split("--");
        const info = {
            entityType: typeParts[0],
            bundle: typeParts[1],
            id: entity.id
        };

        return info;
    }

}
