import React, { Component } from 'react';

import './Video.css';

export default class Video extends Component {
    
    render() {

        const url = 'https://player.vimeo.com/video/' + this.props.url.split("/").pop() + '?autoplay=1&byline=0&portrait=0';

        const containerStyles = {
            padding: '50vh 0 0 0',
            position: 'relative'
        };

        const iframeStyles = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
            width: '100%',
            height: '100%'
        };

        return (
            <div className="video">
                <div style={containerStyles}><iframe src={url} title={url} style={iframeStyles} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        );
    }
}