export default class Drupal {

    baseUrl = "https://data.ajff.org";

    jsonApiBase = "/api/";

    frontPageId = "9e42909b-3f21-41c9-b4ff-c652523a5bc0";

    fourOhFourPageId = "8ceae657-8bf7-4176-bdba-4ed0ba841c96";

    constructor() {
        let currentUri = window.location.origin;
        if (currentUri.indexOf('.platformsh.site') > 0) {
            const uriParts = currentUri.split(".");
            uriParts.splice(0, 2);
            this.baseUrl = 'https://' + uriParts.join(".");
        }
        else if (currentUri.indexOf('.lndo.site') > 0 || currentUri.indexOf('localhost') > 0) {
            // Not a typical use case.  Would rather just pull from production data.
            // If you need a local backend, here is where to do it.
            this.baseUrl = 'http://ajffdata.lndo.site';
        }
    }

    getBaseUrl = () => {
        return this.baseUrl;
    }

    getJsonApiBase = () => {
        return this.jsonApiBase;
    }

    getFrontPageId = () => {
        return this.frontPageId;
    }

    get404PageId = () => {
        return this.fourOhFourPageId;
    }

    get404Meta = () => {
        return {
            type: 'node',
            bundle: 'page',
            uuid: this.get404PageId()
        };
    }

}
