import React, { Component } from 'react';
import ComponentMap from '../../ComponentMap';

export default class RouteResolver extends Component {

    state = {
        entity: null,
        component: null
    };

    componentDidUpdate = (prevProps) => {
        if(window.location.pathname !== prevProps.location.pathname) {
            this.resolvePath();
        }
    }

    componentDidMount = () => {
        this.resolvePath();
    }

    resolvePath = async () => {
        let entity = null;
        let meta = null;

        const query = new URLSearchParams(window.location.search);

        if(this.props.location.pathname.split("/").pop() === 'load' && query.get('id') !== null) {
            meta = {
                type: query.get('type'),
                bundle: query.get('bundle'),
                uuid: query.get('id')
            };
        } else {

            const params = {
                path: this.props.location.pathname,
                _format: 'json'
            };

            const response = await this.props.api.resolvePath(params);

            if(response.hasOwnProperty('data') && response.data.hasOwnProperty('entity')) {
                meta = this.props.api.getEntityMetaFromPath(response);
            } else if (response.hasOwnProperty('status') && response.status === 404) {
                meta = this.props.drupal.get404Meta();
            }

        }

        const entityData = await this.props.api.getEntity(meta.type, meta.bundle, meta.uuid);
        
        if(entityData.hasOwnProperty('data')) {
            entity = entityData.data;    

            const componentMeta = ComponentMap.map.find(element => element.entityType === meta.type && element.entityBundle === meta.bundle);

            if(componentMeta != null) {
                const component = componentMeta.component;
        
                this.setState({
                    component: React.cloneElement(
                        component,
                        {
                            entity: entity,
                            location: this.props.location,
                            api: this.props.api
                        }
                    ),
                    entity: entity
                });
            }
        }
    }
    
    render = () => {
        if(this.state.component) {
            return (
                <>
                    {this.state.component}
                </>
            );
        }
        
        return (<></>);

    }


}