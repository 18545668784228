import React, { Component } from 'react';

import './CardPlaceholder.css';

export default class CardPlaceholder extends Component {

    render() {

        return (
            <div className="card placeholder">
                    <div>
                        <div className="image"></div>

                        <div className="content">
                            <div className="title"></div>
                            <div className="body"></div>
                            <div className="body"></div>
                            <div className="body"></div>
                            <div className="body"></div>
                            <div className="body"></div>
                            <div className="body"></div>
                            <div className="body"></div>
                            <div className="body"></div>

                            <div className="content-fade"></div>
                        </div>
                    </div>
            </div>
        );
    }

}