import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

export default class FilterItem extends Component {

    render() {
        return (
            <div className="filter-item">
                <Form.Check defaultChecked={this.props.selected} onChange={(e) => { this.props.handleChange(this, e) }} value={this.props.value} name={this.props.name} type="radio" label={ this.props.label } entitytype={this.props.entityType} bundle={this.props.bundle} />
            </div>
        );
    }

}