import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Banner from '../Banner/Banner';

import '../../../fonts/fontawesome/css/all.css';
import './BrowseBar.css';

export default class BrowseBar extends Component {

    render() {
        const contexts = [
            {
                id: 'subject',
                label: 'Subject'
            },
            {
                id: 'genre',
                label: 'Type'
            },
            {
                id: 'language',
                label: 'Language'
            },
            {
                id: 'country',
                label: 'Country'
            }
        ];

        let contextOptions = [];
        for(const context of contexts) {
            contextOptions.push(<option key={context.id} value={context.id}>{context.label}</option>);
        }

        return (
            <div className="browse-container">
                <Banner padded={true} text="Browse the Archives by" />

                <div className="browse-bar">
                    <Form>
                        <div className="inputs">
                            <label className="select-label">
                                <Form.Control as="select" className="input-item bundle-select" onChange={this.props.onSelectChange}>
                                    {contextOptions}
                                </Form.Control>
                            </label>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}