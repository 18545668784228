import React, { Component } from 'react';

export default class Text extends Component {

    render() {

        const html = {
            __html: this.props.text
        };

        return (
            <div className="text paragraph" dangerouslySetInnerHTML={html} />
        );
    }

}