import React, { Component } from 'react';
import ContentList from '../../../layout/ContentList/ContentList';
import FilterItem from '../FilterItem/FilterItem';
import { Form, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';


import './Filter.css';

export default class Filter extends Component {

    constructor(props) {
        super(props);

        let open = false;
        if(this.props.value !== 'all') {
            open = true;
        }

        this.state = {
            items: [],
            open: open,
            hasMore: false,
            links: {},
            value: this.props.value
        };
    }

    componentDidMount() {
        this.getInitialFilterItems();
    }

    render() {
        const ariaId = this.getFilterName();
        const { open, hasMore } = this.state;

        let indicator = <FontAwesomeIcon icon={faCaretRight} />;
        if(open) {
            indicator = <FontAwesomeIcon icon={faCaretDown} />;
        }

        let getMore = <div className="no-more"></div>;
        if(hasMore) {
            getMore = <div className="get-more" onClick={this.getMoreItems}>See More</div>;
        }

        return (
            <div className="filter">
                <h5 onClick={() => this.setState({ open: !open })} aria-controls={ariaId} aria-expanded={open}>{ this.props.label }<span className="indicator">{indicator}</span></h5>
                <Collapse in={this.state.open}>

                    <div id={ariaId} className="filter-items">
                        <Form bundle={this.props.bundle}>
                            <ContentList>
                                { this.state.items }
                            </ContentList>
                        </Form>
                        {getMore}
                    </div>
                </Collapse>
            </div>
        );
    }

    getInitialFilterItems = async () => {
        let label = 'title';
        if(this.props.entityType === 'taxonomy_term') {
            label = 'name';
        }

        let options = {};
        if(this.props.entityType === 'node' && (this.props.bundle === 'film' || this.props.bundle === 'person')) {
            options = {
                'filter[outlet][condition][path]': 'outlets.name',
                'filter[outlet][condition][value]': 'ajffrecommends.org'         
            }
        }

        if(this.props.sortFields) {
            for(let sortField of this.props.sortFields) {
                options[`sort[${sortField}][path]`] = sortField;
                if(this.props.reverseSort) {
                    options[`sort[${sortField}][direction]`] = 'DESC';
                }
            }
        } else {
            options['sort[title][path]'] = label;
            if(this.props.reverseSort) {
                options['sort[title][direction]'] = 'DESC';
            }    
        }

        await this.props.api.getEntities(this.props.entityType, this.props.bundle, options).then(
            (response) => {
                return this.processFilterItems(response);
            }
        );
    }

    getFilterName = () => {
        return 'filter--' + this.props.entityType + '--' + this.props.bundle;
    }

    processFilterItems = (response) => {
        const filterName = this.getFilterName();
        let items = this.state.items;
        
        if(items.length === 0) {
            items.push(<FilterItem selected={(this.state.value === 'all')} handleChange={this.props.handleChange} key="all" value="all" keywords="" name={filterName} entityType={this.props.entityType} bundle={this.props.bundle} label="All" />);
        }
        let label = '';

        for(const item of response.data.data) {
            if(this.props.entityType === 'taxonomy_term') {
                label = item.attributes.name;
            } else {
                label = item.attributes.title;
            }

            items.push(<FilterItem selected={(this.state.value === item.id)} handleChange={this.props.handleChange} key={item.id} value={item.id} name={filterName} entityType={this.props.entityType} bundle={this.props.bundle} label={label} />);
        }

        const hasMore = this.props.api.hasNext(response.data.links);

        this.setState({
            items: items,
            hasMore: hasMore,
            links: response.data.links
        });
    }

    getMoreItems = async () => {
        const nextUrl = this.props.api.getNextUrl(this.state.links);
        
        await this.props.api.getResponseFromUrl(nextUrl).then(
            (response) => {
                return this.processFilterItems(response);
            }
        );
    }

}