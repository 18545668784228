import React, { Component } from 'react';

import './MetaItem.css';

export default class MetaItem extends Component {

    render() {
        if(this.props.placeholder) {
            return (
                <div className="meta-item placeholder"></div>
            )
        } else {
            return (
                <div className="meta-item">{this.props.text}{this.props.suffix}</div>
            );
        }
    }

}
