import React, { Component } from 'react';
import Card from '../../components/content/Card/Card';
import BrowseBar from '../../components/ui/BrowseBar/BrowseBar';
import { Waypoint } from 'react-waypoint';
import ContentGrid from '../../components/layout/ContentGrid/ContentGrid';
import CardPlaceholder from '../../components/content/CardPlaceholder/CardPlaceholder';
import Site from '../../siteInfo';
import { Helmet } from 'react-helmet';

import './BrowsePage.css';

export default class BrowsePage extends Component {

    constructor(props) {
        super(props);

        const defaultEntityType = 'taxonomy_term';
        const defaultBundle = 'subject';

        this.state = {
            entityType: defaultEntityType,
            bundle: defaultBundle,
            page: 0,
            hasMore: true,
            renderedItems: [],
            links: {}
        };
    }

    render() {
        let placeholders = null;

        if(this.state.hasMore) {
            placeholders = <ContentGrid small="12" medium="4">
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
            </ContentGrid>;
        }

        return (
            <div className="content-wrapper browse-page">
                <Helmet>
                    <title>Browse | {Site.getPageTitleSlug()}</title>
                </Helmet>

                <BrowseBar onSelectChange={this.handleBundleChange} />

                <div className="page-content container">

                    <div className="item-list">
                        <ContentGrid small="12" medium="4">
                            { this.state.renderedItems }
                        </ContentGrid>

                        <Waypoint onEnter={this.getItems} />

                        {placeholders}

                    </div>

                </div>

            </div>
        );
    }

    handleBundleChange = (e) => {
        const bundle = e.target.value;

        const entityType = 'taxonomy_term';

        this.setState({
            entityType: entityType,
            bundle: bundle,
            page: 0,
            hasMore: true,
            renderedItems: [],
            links: {}
        });

        this.getItems(null, true, entityType, bundle);
    }

    getItems = async (e = null, reset = false, entityType = null, bundle = null) => {
        if(this.state.page === 0 || reset) {
            let options = {
                'page[limit]': 24,
                'sort': 'name',
            };

            if(entityType === null) {
                entityType = this.state.entityType;
            }

            if(bundle === null) {
                bundle = this.state.bundle;
            }

            if(entityType === 'node' && bundle === 'film') {
                options['filter[outlet][condition][path]'] = 'outlets.name';
                options['filter[outlet][condition][value]'] = 'ajffrecommends.org';
            }

            await this.props.api.getEntities(entityType, bundle, options).then(
                (response) => {
                    this.processResponse(response);
                }
            );
        } else {
            if(this.state.hasMore) {
                const nextUrl = this.props.api.getNextUrl(this.state.links);

                if(nextUrl) {
                    await this.props.api.getResponseFromUrl(nextUrl).then(
                        (response) => {
                            this.processResponse(response);
                        }
                    );
                }
            }
        }
    }

    processResponse = (response) => {
        const items = response.data.data;
        const includes = response.data.included;
        const newRenderedItems = this.renderItems(items, includes);

        this.setState({
            links: response.data.links,
            hasMore: this.props.api.hasNext(response.data.links),
            renderedItems: this.state.renderedItems.concat(newRenderedItems),
            page: this.state.page + 1
        });
    }

    renderItems = (items, includes) => {
        const renderedItems = [];

        for(const item of items) {
            let heroImageUrl = '';
            if(item.relationships.hasOwnProperty('field_representative_film')) {
                const representativeFilmField = item.relationships.field_representative_film.data;
                if(representativeFilmField instanceof Object && representativeFilmField.hasOwnProperty("id")) {
                    const representativeFilm = includes.find( ({...attributes}) => attributes.id === representativeFilmField.id);
                    const heroImageMedia = representativeFilm.relationships.field_hero_image.data;
                    if(heroImageMedia instanceof Object && heroImageMedia.hasOwnProperty("id")) {
                        const heroImageFileId = includes.find( ({...attributes}) => attributes.id === heroImageMedia.id).relationships.field_media_image.data.id;
                        heroImageUrl = includes.find( ({...attributes}) => attributes.id === heroImageFileId).links.large.href;
                    }
                }
            }

            let url = '/search?type=' + this.state.entityType + '&bundle=' + this.state.bundle + '&value=' + item.id + '&label=' + item.attributes.name;
            
            renderedItems.push(<Card hideBody={true} key={item.id} url={url} image={heroImageUrl} title={item.attributes.name} body={item.attributes.field_description} />);
        }

        return renderedItems;

    }

}
