import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitter, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FooterForm from '../../ui/MailChimp/FooterForm/FooterForm';

import './Footer.css';

export default class Footer extends Component {

    render() {

        return (
            <footer className="page-footer">
                <div className="container light">
                    <div className="row">

                        <div className="col-sm-12 col-lg-4">
                            <h4>Sign up for AJFF Emails</h4>
                            <FooterForm />
                        </div>

                        <div className="col-sm-12 col-lg-4">
                            <h4>Follow AJFF</h4>
                            <div className="social-icons">
                                <a href="https://www.facebook.com/atljewishfilm/" target="_blank" rel="noopener noreferrer"><div className="social-icon"><FontAwesomeIcon icon={faFacebookSquare} /></div></a>
                                <a href="https://www.youtube.com/channel/UCSqdHxDboDFnfuGfO1qSufg" target="_blank" rel="noopener noreferrer"><div className="social-icon"><FontAwesomeIcon icon={faYoutube} /></div></a>
                                <a href="https://twitter.com/atljewishfilm" target="_blank" rel="noopener noreferrer"><div className="social-icon"><FontAwesomeIcon icon={faTwitter} /></div></a>
                                <a href="https://www.instagram.com/atljewishfilm/" target="_blank" rel="noopener noreferrer"><div className="social-icon"><FontAwesomeIcon icon={faInstagram} /></div></a>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-4">
                            <div className="footer-menu headline">
                                <Link to="/content/faq">FAQ</Link>
                                <Link to="/content/contact">Contact</Link>
                                <Link to="/content/support-us">Support</Link>
                                <a href="https://www.ajff.org" target="_blank" rel="noopener noreferrer">ajff.org</a>
                            </div>
                        </div>

                    </div>

                    <div className="row footer-sponsors">

                        <div className="col-sm-12">
                            <div className="sponsor-link">
                                <Link to="/content/support-us" className="button sponsors">AJFF Recommends is made possible by generous supporters. Click here for full list.</Link>
                            </div>
                        </div>
                    </div>

                    <div className="row footer-copyright headline">
                        <div className="col-sm-12 col-lg-6">
                            © 2020 Atlanta Jewish Film Festival. All rights reserved. <Link to="/privacy-policy">Privacy Policy</Link>

                        </div>
                        <div className="col-sm-12 col-lg-6 signature">
                            Site by <a href="https://coolbluei.com" target="_blank" rel="noreferrer noopener">Cool Blue Interactive</a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

}