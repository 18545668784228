import React from 'react';
import ContentPage from './pages/ContentPage/ContentPage';
import FilmDetailPage from './pages/FilmDetailPage/FilmDetailPage';

export default class ComponentMap {

    static map = [
        {
            entityType: 'node',
            entityBundle: 'page',
            component: <ContentPage />
        },
        {
            entityType: 'node',
            entityBundle: 'film',
            component: <FilmDetailPage />
        }
    ];
}
