import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../ui/Modal/Modal';
import Video from '../../media/Video/Video';

import './MediaTeaser.css';

export default class MediaTeaser extends Component {
    
    constructor(props) {
        super(props);

        const origBodyOverflow = window.getComputedStyle(document.body).overflow;

        this.state = {
            modalContent: null,
            origBodyOverflow: origBodyOverflow
        };
    }
    
    render() {
        let caption = null;

        if(this.props.hasOwnProperty('caption')) {
            caption = <div className="caption">{this.props.caption}</div>;
        }

        const classes = 'media-teaser ' + this.props.type;

        if(this.props.placeholder) {
            return (
                <div className="media-teaser placeholder"></div>
            );
        } else {

            return (
                <>
                    <div className={classes} onClick={this.expandMedia} media-type={this.props.type} media-id={this.props.mediaId}>
                        <Image src={this.props.imgSrc} />
                        {caption}
                        <div className="play">
                            <FontAwesomeIcon icon={faPlay} />
                        </div>
                    </div>
                    {this.state.modalContent}
                </>
            );
        }
    }

    expandMedia = () => {
        let modal = null;
        let media = null;

        this.props.api.getEntity('media', this.props.mediaBundle, this.props.mediaId).then(
            (entity) => {

                if(this.props.mediaBundle === 'image') {
                    const file = this.props.api.lookupInclude(entity.data.included, entity.data.data.relationships.field_media_image.data.id);
                    media = <Image key={file.id} src={file.links.ajff_rec_hero.href} />;
                }

                if(this.props.mediaBundle === 'remote_video') {
                    const videoUrl = entity.data.data.attributes.field_media_oembed_video;
                    media = <Video url={videoUrl} />
                }

                modal = <Modal handleClose={this.handleModalClose}>{media}</Modal>;

                this.setState({
                    modalContent: modal
                });

                document.body.style.overflow = 'hidden';

            }
        );
        
    }

    handleModalClose = () => {
        document.body.style.overflow = this.state.origBodyOverflow;

        this.setState({
            modalContent: null
        });
    }
}