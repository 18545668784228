import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './Card.css';

export default class Card extends Component {

    state = {
        imageUrl: null,
    };

    render() {
        let body = null;

        const classNames = [
            'content',
            this.props.variant,
        ];

        const classNameStr = classNames.join(' ').trim();

        let classes = "card-teaser";

        if(this.props.small) {
            classes += " small";
        }

        if(this.props.hideBody) {
            classes += " no-body";
        } else {
            const innerBody = {
                __html: this.props.body
            };

            body  = (
                <div className="body" dangerouslySetInnerHTML={innerBody} />
            );
        }

        return (
            <Link to={this.props.url}>
                <div className={classes}>
                    <div className="details">
                        <Image src={this.props.image} />

                        <div className={classNameStr}>
                            <h2>{ this.props.title }</h2>
                            {body}
                            <div className="content-fade"></div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

}