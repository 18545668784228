import React, { Component } from 'react';

export default class ContentGrid extends Component {

    render() {
        const renderedChildren = React.Children.map(this.props.children, child => {
            const classNames = this.getClassNames();
            return (
                <div className={ classNames }>
                    { child }
                </div>
            );
        });

        const rowClasses = this.getRowClassNames();
    
        return (
            <div className={ rowClasses }>
                { renderedChildren }
            </div>
        );
    }

    getRowClassNames = () => {
        let classNames = [
            'row',
        ];

        if(this.props.centerIncompleteRow) {
            classNames.push('justify-content-center');
        }

        const classNamesStr = classNames.join(' ');

        return classNamesStr;
    }

    getClassNames = () => {
        let classNames = [];
        if(this.props.small) {
            classNames.push('col-sm-' + this.props.small);
        }

        if(this.props.medium) {
            classNames.push('col-md-' + this.props.medium);
        }

        if(this.props.large) {
            classNames.push('col-lg-' + this.props.large);
        }

        if(this.props.xlarge) {
            classNames.push('col-xl-' + this.props.xlarge);
        }

        const classNameStr = classNames.join(' ');

        return classNameStr;

    }

}