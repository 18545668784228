import React, { Component } from 'react';
import { default as ElasticCarousel, consts } from 'react-elastic-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';

import './Slider.css';

export default class Slider extends Component {
    
    render() {

        const sliderSettings = {
            itemsToShow: 1,
            pagination: false,
            disableArrowsOnEnd: false,
            enableAutoPlay: true,
            autoPlaySpeed: 4500
        };    


        return (
            <div className="slider">

                <ElasticCarousel renderArrow={this.arrows} {...sliderSettings}>

                    {this.props.children}

                </ElasticCarousel>

            </div>
        );

    }

    arrows = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? this.arrow('left', onClick, isEdge) : this.arrow('right', onClick, isEdge);

        return (
            pointer
        );
    }

    arrow = (dir, onClick, isEdge) => {
        let classes = "arrow-icon arrow-left";
        let icon = faCaretLeft;
        if(dir === 'right') {
            classes = "arrow-icon arrow-right";
            icon = faCaretRight;
        }

        return(
            <div className={classes} onClick={onClick} disabled={isEdge}><FontAwesomeIcon icon={icon} /></div>
        )
    }
}