import React, { Component } from 'react';
import Meta from '../../components/content/Meta/Meta';
import HeroImage from '../../components/media/HeroImage/HeroImage';
import Carousel from '../../components/ui/Carousel/Carousel';
import MediaTeaser from '../../components/media/MediaTeaser/MediaTeaser';
import Title from '../../components/atoms/Title/Title';
import Body from '../../components/atoms/Body/Body';
import Site from '../../siteInfo';
import { Image } from 'react-bootstrap';
import SidebarDetails from "../../components/content/SidebarDetails/SidebarDetails";
import Metadata from '../../components/atoms/Meta/Meta';

import './FilmDetailPage.css';

export default class FilmDetailPage extends Component {

    state = {
        media: [],
        entity: this.props.entity
    };

    componentDidUpdate = (prevProps) => {
        if(prevProps.location.pathname !== this.props.location.pathname) {

            const sliderItems = this.getSliderItems();

            this.setState({
                media: sliderItems,
                entity: this.props.entity
            });
        }
    }

    componentDidMount = () => {

        document.title = Site.getPageTitleSlug() + ' | ' + this.props.entity.data.attributes.title;

        const sliderItems = this.getSliderItems();

        this.setState({
            media: sliderItems
        });

    }

    render() {
        let heroImage = <HeroImage placeholder={true} />
        let carousel = <Carousel api={ this.props.api } placeholder={true} />
        let title = <Title placeholder={true} />
        let meta = this.getMetaPlaceholders();
        let synopsis = <Body placeholder={true} />
        let sidebar = null;
        let posterImage = null;

        if(this.state.entity.hasOwnProperty('data')) {
            heroImage = null;
            carousel = null;
            title = <Title text={this.state.entity.data.attributes.title} />;
            meta = this.getMeta();
            synopsis = <Body text={this.state.entity.data.attributes.field_synopsis_for_ajffrecommend} />
            sidebar = <SidebarDetails api={ this.props.api } subjects={this.state.entity.data.relationships.field_subjects.data} />;

            if(this.state.entity.hasOwnProperty('data') && this.state.entity.data.relationships.field_hero_image.data instanceof Object) {
                const imgInclude = this.props.api.lookupInclude(this.state.entity.included, this.state.entity.data.relationships.field_hero_image.data.id);
                const heroImageEntity = this.props.api.lookupInclude(this.state.entity.included, imgInclude.relationships.field_media_image.data.id);
    
                if(heroImageEntity.hasOwnProperty('links')) {
                    heroImage = <HeroImage image={heroImageEntity.links.ajff_rec_hero?.href} />
                }
            }

            if(this.state.entity.hasOwnProperty('data') && this.state.entity.data.relationships.field_poster_image.data instanceof Object) {
                const posterImgInclude = this.props.api.lookupInclude(this.state.entity.included, this.state.entity.data.relationships.field_poster_image.data.id);
                const posterImageEntity = this.props.api.lookupInclude(this.state.entity.included, posterImgInclude.relationships.field_media_image.data.id);
    
                if(posterImageEntity.hasOwnProperty('links')) {
                    posterImage = (
                        <div className="col-lg-3">
                            <Image src={posterImageEntity.links.large.href} />
                        </div>
                    );
                }
            }
    
            const sliderSettings = {
                itemsToShow: 3,
                pagination: false,
                disableArrowsOnEnd: false,
                itemPadding: [0,10,0,10]
            };    

            const sliderItems = this.getSliderItems();

            if(sliderItems.length > 0) {
                if(sliderItems.length <= sliderSettings.itemsToShow) {
                    sliderSettings.showArrows = false;
                }

                carousel = <Carousel api={ this.props.api } sliderSettings={sliderSettings}>{sliderItems}</Carousel>;
            }
        }

        const metadata = <Metadata data={this.props.entity.data.attributes.meta} />

        return (
            <div className="content-wrapper film-detail-page">
                {metadata}

                <div className="container page-content">

                    <div className="row">

                        <div className="film-details col-sm-12 col-md-9">
                            
                            {heroImage}

                            <div className="film-content">

                                {carousel}

                                {title}

                                <div className="row film-details">
                                    {meta}

                                    {posterImage}
                                </div>

                                <div className="film-synopsis">
                                    {synopsis}
                                </div>

                            </div>
                        </div>

                        <div className="sidebar col-sm-12 col-md-3">
                            {sidebar}
                        </div>

                    </div>

                </div>

            </div>
        );
    }
    

    getSliderItems = () => {
        let items = [];

        if(this.state.entity.hasOwnProperty('data') && this.state.entity.data.hasOwnProperty('relationships') && this.state.entity.data.relationships.hasOwnProperty('field_media')) {
            for(const media of this.state.entity.data.relationships.field_media.data) {

                let image = null;
                let caption = null;
                const include = this.props.api.lookupInclude(this.state.entity.included, media.id);

                if(media.type === 'media--image') {
                    image = this.props.api.lookupInclude(this.state.entity.included, include.relationships.field_media_image.data.id);
                } else if(media.type === 'media--remote_video') {
                    image = this.props.api.lookupInclude(this.state.entity.included, include.relationships.thumbnail.data.id);
                }

                if(include.relationships.field_media_type.hasOwnProperty('data')) {
                    caption = this.props.api.lookupInclude(this.state.entity.included, include.relationships.field_media_type.data.id);
                }

                items.push(<MediaTeaser key={media.id} api={ this.props.api } mediaId={media.id} mediaBundle={media.type.split("--").pop()} type={media.type} caption={caption.attributes.name} imgSrc={image.links.ajff_rec_thumbnail.href} />);

            }
        }

        return items;
    }

    getMeta = () => {
        return (
            <>
                <div className="col">
                    <Meta key='field_genre' api={ this.props.api } field='field_genre' isInclude={true} isMulti={false} entity={this.state.entity} label="Type" />
                    <Meta key='field_year' api={ this.props.api } field='field_year' isInclude={false} isMulti={false} entity={this.state.entity} label="Year" />
                    <Meta key='field_directors' api={ this.props.api } field='field_directors' isInclude={true} isMulti={true} entity={this.state.entity} label="Directors" />
                    <Meta key='field_run_time' api={ this.props.api } field='field_run_time' isInclude={false} isMulti={false} entity={this.state.entity} label="Run Time" suffix=" minutes" />
                    <Meta key='field_countries' api={ this.props.api } field='field_countries' isInclude={true} isMulti={true} entity={this.state.entity} label="Country" />
                    <Meta key='field_languages' api={ this.props.api } field='field_languages' isInclude={true} isMulti={true} entity={this.state.entity} label="Languages" />
                    <Meta key='field_festival_series' api={ this.props.api } field='field_festival_series' isInclude={true} isMulti={true} entity={this.state.entity} label="Featured at" />
                </div>

                <div className="col">
                    <Meta key='field_subjects' api={ this.props.api } field='field_subjects' isInclude={true} isMulti={true} entity={this.state.entity} label="Subjects" />
                </div>
            </>
        );
    }

    getMetaPlaceholders = () => {
        return [
            <Meta key={1} api={ this.props.api } placeholder={true} isMulti={false} />,
            <Meta key={2} api={ this.props.api } placeholder={true} isMulti={false} />,
            <Meta key={3} api={ this.props.api } placeholder={true} isMulti={true} />,
            <Meta key={4} api={ this.props.api } placeholder={true} isMulti={false} />,
            <Meta key={5} api={ this.props.api } placeholder={true} isMulti={true} />,
            <Meta key={6} api={ this.props.api } placeholder={true} isMulti={true} />,
            <Meta key={7} api={ this.props.api } placeholder={true} isMulti={true} />,
            <Meta key={8} api={ this.props.api } placeholder={true} isMulti={true} />,
        ];
    }

    getSubjects = () => {
        return this.state.entity.data.relationships.field_subjects.data;
    }

    getRandomSubject () {
        const subjects = this.state.entity.data.relationships.field_subjects.data;
        if (subjects) {
            if (subjects instanceof Array) {
                let results = [];
                for(const subject of subjects) {
                    results.push(subject.id);
                }
                return results;
            }
            return [subjects.id];
        }
        return 'Unknown';
    }
}
