import React, { Component } from 'react';
import Card from '../Card/Card';
import CardPlaceholder from '../CardPlaceholder/CardPlaceholder';
import ContentGrid from '../../layout/ContentGrid/ContentGrid';

export default class ItemList extends Component {

    state = {
        entity: null,
        items: []
    };

    componentDidMount = async () => {
        await this.props.api.getEntity('paragraph', 'item_list', this.props.entityId).then(
            async (entity) => {
                const items = this.getItems(entity.data);

                this.setState({
                    entity: entity.data,
                    items: items
                });
            }
        );
    }

    render() {
        if(this.state.entity === null) {
            return (
                <div className="item-list placeholder">
                    <ContentGrid small="12" medium="4">
                        <CardPlaceholder />
                        <CardPlaceholder />
                        <CardPlaceholder />
                        <CardPlaceholder />
                        <CardPlaceholder />
                        <CardPlaceholder />
                    </ContentGrid>
                </div>
            );
        } else {
            return (
                <div className="item-list">
                    <ContentGrid small="12" medium="4">
                        {this.state.items}
                    </ContentGrid>
                </div>
            );
        }
    }

    getItems = (entity) => {
        let item;
        let items = [];

        for(item of entity.data.relationships.field_items.data) {
            const itemType = item.type.split("--");
            const include = this.props.api.lookupInclude(entity.included, item.id);
            let body;

            if(itemType[1] === 'content_item') {
                const node = this.props.api.lookupInclude(entity.included, include.relationships.field_content.data[0].id);
                let url = '/node/' + node?.attributes.drupal_internal__nid;
                if(node?.attributes.path.alias !== "") {
                    url = node?.attributes.path.alias;
                }

                let heroImageUrl = '';
                const heroImageMedia = node?.relationships.field_hero_image.data;
                if(heroImageMedia instanceof Object && heroImageMedia.hasOwnProperty("id")) {
                    const heroImageFileId = this.props.api.lookupInclude(entity.included, heroImageMedia.id).relationships.field_media_image.data.id;
                    heroImageUrl = this.props.api.lookupInclude(entity.included, heroImageFileId).links.large?.href;
                }

                if(node?.type === 'node--film') {
                    body = node.attributes.field_synopsis_for_ajffrecommend;
                }
    
                items.push(<Card key={node?.id} url={url} image={heroImageUrl} title={node?.attributes.title} body={body} />);
            } else if(itemType[1] === 'taxonomy_item') {
                const term = this.props.api.lookupInclude(entity.included, include.relationships.field_taxonomy_term.data.id);
                const termBundle = term.type.split("--");
                let url = '/search?type=taxonomy_term&bundle=' + termBundle[1] + '&value=' + term.id + '&label=' + term.attributes.name;

                let heroImageUrl = '';
                let filmId = null;
                if(term.relationships.field_representative_film.data instanceof Object) {
                    filmId = term.relationships.field_representative_film.data.id;

                    const representativeFilm = this.props.api.lookupInclude(entity.included, filmId);
                    const heroImageMedia = this.props.api.lookupInclude(entity.included, representativeFilm.relationships.field_hero_image.data.id);
                    if(heroImageMedia instanceof Object && heroImageMedia.hasOwnProperty("id")) {
                        const heroImageFileId = this.props.api.lookupInclude(entity.included, heroImageMedia.id).relationships.field_media_image.data.id;
                        heroImageUrl = this.props.api.lookupInclude(entity.included, heroImageFileId).links.large.href;
                    }
                }

                items.push(<Card key={term.id} url={url} image={heroImageUrl} title={term.attributes.name} body={term.attributes.field_description} />);
            }
        }

        return items;
    }

}
