import React, { Component } from 'react';
import Slider from '../../components/ui/Slider/Slider';
import Slide from '../../components/ui/Slide/Slide';
import ContentGrid from '../../components/layout/ContentGrid/ContentGrid';
import SearchBar from '../../components/ui/Search/SearchBar/SearchBar';
import CardPlaceholder from '../../components/content/CardPlaceholder/CardPlaceholder';
import ItemList from '../../components/content/ItemList/ItemList';
import Site from '../../siteInfo';
import { Helmet } from 'react-helmet';

import '../../scss/common/main.css';
import './FrontPage.css';
import DrupalLink from "../../components/drupal/DrupalLink";

export default class FrontPage extends Component {

    state = {
        entity: {}
    };

    componentDidMount = async () => {
        await this.props.api.getEntity('node', 'page', this.props.drupal.getFrontPageId()).then(
            (entity) => {
                this.setState({
                    entity: entity.data,
                });
            }
        );
    }
    
    render() {

        let cardPlaceholders = this.getCardPlaceholders();
        let content = [];
        let heroes = [];
        let slider = null;

        if(this.state.entity.hasOwnProperty('data') && this.state.entity.data.relationships.field_content.data.length > 0) {
            for(const item of this.state.entity.data.relationships.field_content.data) {
                if(item.type === 'paragraph--item_list') {
                    content.push(<ItemList key={item.id} entityId={item.id} api={this.props.api} />);
                }
            }
        }

        if(this.state.entity.hasOwnProperty('data')) {
            if(this.state.entity.data.relationships.field_heroes.data.length > 0) {
                for(let heroRef of this.state.entity.data.relationships.field_heroes.data) {
                    const hero = this.props.api.lookupInclude(this.state.entity.included, heroRef.id);

                    let url = null;

                    const heroImageMedia = this.props.api.lookupInclude(this.state.entity.included, hero.relationships.field_hero_image.data.id);
                    const heroImageFile = this.props.api.lookupInclude(this.state.entity.included, heroImageMedia.relationships.field_media_image.data.id);
                    const heroImageUrl = heroImageFile.links.ajff_rec_slider?.href;

                    if(hero.attributes.field_link !== null) {
                      url = DrupalLink.toUrl(hero.attributes.field_link);
                    }

                    heroes.push(<Slide key={hero.id} image={heroImageUrl} title={hero.attributes.field_title} link={url} />);

                    slider = <Slider>{heroes}</Slider>;
                }
            }
        }

        return (
            <div className="content-wrapper front">
                <Helmet>
                    <title>{Site.getPageTitleSlug()}</title>
                </Helmet>

                {slider}

                <SearchBar api={ this.props.api } />

                <div className="page-content container">

                    {cardPlaceholders}

                    {content}
                </div>
            </div>
        );


    }

    getCardPlaceholders = () => {
        if(this.state.entity === null) {
            return (
                <ContentGrid small="12" medium="6" large="4">
                    <CardPlaceholder />
                    <CardPlaceholder />
                    <CardPlaceholder />
                    <CardPlaceholder />
                    <CardPlaceholder />
                    <CardPlaceholder />
                </ContentGrid>
            );
        }

        return <></>;
    }
}