import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Banner from '../../Banner/Banner';

import './SearchBar.css';

export default class SearchBar extends Component {

    constructor(props) {
        super(props);
        
        const defaultEntityType = 'node';
        const defaultBundle = 'film';

        this.state = {
            entityType: defaultEntityType,
            bundle: defaultBundle,
            value: null,
            title: null
        };
    }

    render() {
        if(this.state.hasOwnProperty('value') && this.state.value != null && this.state.value !== 'all') {
            let url = '/search?type=' + this.state.entityType + '&bundle=' + this.state.bundle + '&value=' + this.state.value + '&label=' + this.state.title;

            if(this.state.entityType === 'node' && this.state.bundle === 'film') {
                url = '/load?type=' + this.state.entityType + '&bundle=' + this.state.bundle + '&id=' + this.state.value;
            }
                        
            return (
                <Redirect push to={url} />
            );
        }

        const contexts = [
            {
                id: 'film',
                label: 'Film Title'
            },
            {
                id: 'subject',
                label: 'Subject'
            },
            {
                id: 'person',
                label: 'Director'
            },
            {
                id: 'genre',
                label: 'Type'
            },
            {
                id: 'language',
                label: 'Language'
            },
            {
                id: 'country',
                label: 'Country'
            }
        ];

        const selectStyles = {
            dropdownIndicator: () => ({
                display: 'none',
            }),
            indicatorSeparator: () => ({
                display: 'none',
            })
        };

        let contextOptions = [];
        for(const context of contexts) {
            contextOptions.push(<option key={context.id} value={context.id}>by {context.label}</option>);
        }

        return (
            <>
                <div className="search-container">
                    <Banner padded={true} text="Over 20 Years of the Atlanta Jewish Film Festival" />
                    <div className="container">
                        <div className="search-bar">
                            <Form>
                                <div className="inputs">
                                    <label className="select-label light">
                                        <Form.Control as="select" className="input-item bundle-select" onChange={this.handleBundleChange}>
                                            {contextOptions}
                                        </Form.Control>
                                    </label>
                                    <AsyncSelect 
                                        placeholder="Search films by Subject, Title, and more..." 
                                        openMenuOnClick={false} 
                                        openMenuOnFocus={false}
                                        loadOptions={this.updateSearchOptions}
                                        noOptionsMessage={this.noOptionsText}
                                        styles={selectStyles}
                                        onChange={this.handleSelectChange}
                                        className="input-item item-select"
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'rgba(0,0,0,0)'
                                            }
                                        })}
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    processFilterItems = (response) => {
        const items = [];
        let label = '';

        for(const item of response.data.data) {
            let keywords = '';

            if(this.state.entityType === 'node') {
                label = item.attributes.title;
            } else {
                label = item.attributes.name;
            }

            if(item.attributes.hasOwnProperty('field_related_keywords') && item.attributes.field_related_keywords instanceof Array && item.attributes.field_related_keywords.length > 0) {
                for(const keyword of item.attributes.field_related_keywords) {
                    keywords = keywords + keyword;
                }
            }

            const search = label + " " + keywords;

            items.push({
                value: item.id,
                label: label,
                search: search.trim(),
                entityType: this.state.entityType,
                bundle: this.state.bundle
            });
        }

        return items;
    }

    handleBundleChange = (e) => {
        const bundle = e.target.value;

        const entityType = this.getEntityTypeFromBundle(bundle);

        this.setState({
            entityType: entityType,
            bundle: bundle
        });
    }

    handleSelectChange = (e) => {
        const value = e.value;

        this.setState({
            value: value,
            title: e.label
        });
    }

    getEntityTypeFromBundle = (bundle) => {
        if(bundle === 'film' || bundle === 'person' || bundle === 'festival_series') {
            return 'node';
        }

        return 'taxonomy_term';
    }

    updateSearchOptions = async (string, action) => {
        let params = {};

        if(string !== '') {
            if(this.state.entityType === 'node') {
                params = {
                    'sort[title][path]': 'title',
                    'filter[title][path]': 'title',
                    'filter[title][value]': string,
                    'filter[title][operator]': 'CONTAINS',
                    'fields[node--film]': 'title',
                    'filter[outlet][condition][path]': 'outlets.name',
                    'filter[outlet][condition][value]': 'ajffrecommends.org'
                };

                if(this.state.bundle === 'film') {
                    params['sort[title][path]'] = 'field_sort_title';
                }
            } else {
                params = {
                    'sort[title][path]': 'name',
                    'filter[title][path]': 'name',
                    'filter[title][value]': string,
                    'filter[title][operator]': 'CONTAINS'
                };

                if(this.state.bundle === 'subject') {
                    params = {
                        'filter[title-group][group][conjunction]': 'OR',
                        'filter[title][condition][path]': 'name',
                        'filter[title][condition][value]': string,
                        'filter[title][condition][operator]': 'CONTAINS',
                        'filter[title][condition][memberOf]': 'title-group',
                        'filter[keyword][condition][path]': 'field_related_keywords',
                        'filter[keyword][condition][value]': string,
                        'filter[keyword][condition][operator]': 'CONTAINS',
                        'filter[keyword][condition][memberOf]': 'title-group',
                        'sort[title-group][path]': 'name'
                    };
                }
            }
        }

        const items = await this.props.api.getEntities(this.state.entityType, this.state.bundle, params).then(
            (response) => {
                return this.processFilterItems(response);
            }
        );

        return items;
    }

    noOptionsText = (input) => {
        return "";
    }

}