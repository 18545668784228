import React, { Component } from 'react';
import { default as ElasticCarousel, consts } from 'react-elastic-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import MediaTeaser from '../../media/MediaTeaser/MediaTeaser';
import ContentGrid from '../../layout/ContentGrid/ContentGrid';

import './Carousel.css';

export default class Carousel extends Component {
    
    render() {

        if(this.props.placeholder) {
            return (
                <div className="carousel placeholder">
                    <ContentGrid small="4">
                        <MediaTeaser api={ this.props.api } placeholder={true} />
                        <MediaTeaser api={ this.props.api } placeholder={true} />
                        <MediaTeaser api={ this.props.api } placeholder={true} />
                    </ContentGrid>
                </div>
            )
        } else {
            return (
                <div className="carousel">
    
                    <ElasticCarousel renderArrow={this.arrows} {...this.props.sliderSettings}>
    
                        {this.props.children}
    
                    </ElasticCarousel>
    
                </div>
            );
        }

    }

    arrows = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? this.arrow('left', onClick, isEdge) : this.arrow('right', onClick, isEdge);

        return (
            pointer
        );
    }

    arrow = (dir, onClick, isEdge) => {
        let classes = "arrow-icon arrow-left";
        let icon = faCaretLeft;
        if(dir === 'right') {
            classes = "arrow-icon arrow-right";
            icon = faCaretRight;
        }

        return(
            <div className={classes} onClick={onClick} disabled={isEdge}><FontAwesomeIcon icon={icon} /></div>
        )
    }
}