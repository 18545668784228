import React, { Component } from 'react';

import './Slide.css';

export default class Slide extends Component {
    
    render() {
        let title = null;
        let classes = 'slide';

        if(this.props.title != null) {
            title = (
                <div className="slide-title">
                    <h2>{this.props.title}</h2>
                </div>
            );
        }

        const styles = {
            'backgroundImage': 'url("' + this.props.image + '")'
        };

        if(this.props.link) {
            classes += ' linked';
        }

        return (
            <div onClick={this.fireLink} className={classes} style={styles}>
                {title}
            </div>
        );
    }

    fireLink = () => {
        window.location.href = this.props.link;
    }
}