import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Drupal from '../src/drupalConnection';
import Api from '../src/api';

import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import FilmSearchPage from './pages/FilmSearchPage/FilmSearchPage';
import FrontPage from './pages/FrontPage/FrontPage';
import BrowsePage from './pages/BrowsePage/BrowsePage';
import RouteResolver from './pages/RouteResolver/RouteResolver';

class App extends Component {

  state = {
      drupal: null,
      api: null
  };

  constructor(props) {
      super(props);

      const drupal = new Drupal();
      const api = new Api(drupal);

      this.state = {
          drupal: drupal,
          api: api
      };
  }

  componentDidUpdate = (prevProps) => {
      if(this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
  }

  render() {
    let pageClass = 'downpage';

    if(this.props.location.pathname === '/') {
        pageClass = 'front';
    }

    return (
        <div className={ `page-wrapper ${pageClass}` }>
            <Header />

            <Switch>
              <Route path="/" exact render={(props) => (< FrontPage {...props} drupal={this.state.drupal} api={this.state.api} />)} />
              <Route path="/search" exact render={(props) => (< FilmSearchPage {...props} drupal={this.state.drupal} api={this.state.api} />)} />
              <Route path="/browse" exact render={(props) => (< BrowsePage {...props} drupal={this.state.drupal} api={this.state.api} />)} />
              <Route path="/*" render={(props) => (< RouteResolver {...props} drupal={this.state.drupal} api={this.state.api} />)} />
            </Switch>

            <Footer />
        </div>
    );
  }
}

export default withRouter(App);
