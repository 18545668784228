import React, { Component } from 'react';

export default class CurrentFilterIndicator extends Component {

    render() {
        let activeFilterLabels = [];

        for(let bundle in this.props.filters) {
            if(this.props.filters[bundle].id !== 'all') {
                activeFilterLabels.push(this.props.filters[bundle].label);
            }
        }
        
        if(activeFilterLabels.length > 0) {
            const list = activeFilterLabels.join(', ');

            return (
                <h5 className="current-filter-indicator">
                    Films filtered by: {list}
                </h5>
            );
        }

        return <></>;
    }
}