import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';
import ContentGrid from '../../components/layout/ContentGrid/ContentGrid';
import Card from '../../components/content/Card/Card';
import Filter from '../../components/ui/Search/Filter/Filter';
import CardPlaceholder from '../../components/content/CardPlaceholder/CardPlaceholder';
import Banner from '../../components/ui/Banner/Banner';
import Site from '../../siteInfo';
import CurrentFilterIndicator from '../../components/ui/CurrentFilterIndicator/CurrentFilterIndicator';
import BackToTop from '../../components/ui/BackToTop/BackToTop';
import { Helmet } from 'react-helmet';

import './FilmSearchPage.css';

export default class FilmSearchPage extends Component {

    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);

        this.state = {
            page: 0,
            renderedFilms: [],
            links: {},
            hasMore: true,
            noResults: false,
            filters: {
                subject: {
                    id: 'all',
                    label: 'All'
                },
                country: {
                    id: 'all',
                    label: 'All'
                },
                language: {
                    id: 'all',
                    label: 'All'
                },
                genre: {
                    id: 'all',
                    label: 'All'
                },
                person: {
                    id: 'all',
                    label: 'All'
                },
                festival_series: {
                    id: 'all',
                    label: 'All'
                }
            },
            isLoading: false
        };

        if(query.get('bundle') !== null && query.get('value') !== null) {
            this.state.filters[query.get('bundle')] = {
                id: query.get('value'),
                label: query.get('label')
            };
        }

        document.title = Site.getPageTitleSlug() + ' | Search';
    
    }

    render() {
        let placeholders = null;
        let infiniteScrollTrigger = null;

        if(this.state.hasMore) {
            placeholders = <ContentGrid small="12" medium="6">
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
            </ContentGrid>;
        }

        let noResults = null;
        
        if(this.state.noResults) {
            noResults = (
                <h5>There are no films meeting your filter criteria. Please try removing one of your filters to widen your search.</h5>
            );
        }

        infiniteScrollTrigger = this.renderInfiniteScrollTrigger();

        return (
            <div className="content-wrapper">
                <Helmet>
                    <title>Search | {Site.getPageTitleSlug()}</title>
                </Helmet>

                <Banner text="Browse the AJFF Archives" />
                <div className="page-content search-page">

                    <div className="container">

                        <div className="row">

                            <div className="film-filters col-sm-5 col-md-3">
                                <h4>Filter your results by</h4>

                                <Filter api={ this.props.api } handleChange={this.handleFilterChange} value={this.state.filters.subject.id} entityType="taxonomy_term" bundle="subject" label="Subject" />
                                <Filter api={ this.props.api } handleChange={this.handleFilterChange} value={this.state.filters.country.id} entityType="taxonomy_term" bundle="country" label="Country" />
                                <Filter api={ this.props.api } handleChange={this.handleFilterChange} value={this.state.filters.language.id} entityType="taxonomy_term" bundle="language" label="Language" />
                                <Filter api={ this.props.api } handleChange={this.handleFilterChange} value={this.state.filters.genre.id} entityType="taxonomy_term" bundle="genre" label="Type" />
                                <Filter api={ this.props.api } handleChange={this.handleFilterChange} reverseSort={true} value={this.state.filters.festival_series.id} entityType="node" bundle="festival_series" label="Featured at" />
                                <Filter api={ this.props.api } handleChange={this.handleFilterChange} sortFields={['field_last_name', 'field_first_name']} value={this.state.filters.person.id} entityType="node" bundle="person" label="Director" />
                            </div>

                            <div className="film-list col-sm-7 col-md-9">

                                <CurrentFilterIndicator filters={this.state.filters} />

                                { noResults }

                                <ContentGrid small="12" medium="6">
                                    { this.state.renderedFilms }
                                </ContentGrid>

                                {infiniteScrollTrigger}

                                {placeholders}
                                
                            </div>

                        </div>

                    </div>

                </div>

                <BackToTop />

            </div>
        );
    }

    renderInfiniteScrollTrigger = () => {
        if(this.state.isLoading) {
            return null;
        }

        return <Waypoint onEnter={this.getFilms} />;
    }

    getFilms = async () => {
        this.setState({
            isLoading: true
        });

        if(this.state.page === 0) {
            let options = {
                'page[limit]': 24,
                'sort': 'field_sort_title',
                'filter[outlet][condition][path]': 'outlets.name',
                'filter[outlet][condition][value]': 'ajffrecommends.org'          
            };

            options = this.addFilterOptions(options);

            await this.props.api.getEntities('node', 'film', options).then(
                (response) => {
                    this.processFilmResponse(response);
                }
            );
        } else {
            if(this.state.hasMore) {
                const nextUrl = this.props.api.getNextUrl(this.state.links);

                if(nextUrl) {
                    await this.props.api.getResponseFromUrl(nextUrl).then(
                        (response) => {
                            this.processFilmResponse(response);
                        }
                    );
                }
            }
        }
    }

    processFilmResponse = (response) => {
        const films = response.data.data;
        const includes = response.data.included;
        const newRenderedFilms = this.renderFilms(films, includes);
        let noResults = false;

        const renderedFilms = this.state.renderedFilms.concat(newRenderedFilms);

        if(renderedFilms.length === 0) {
            noResults = true;
        }

        this.setState({
            links: response.data.links,
            hasMore: this.props.api.hasNext(response.data.links),
            renderedFilms: this.state.renderedFilms.concat(newRenderedFilms),
            page: this.state.page + 1,
            noResults: noResults,
            isLoading: false
        });
    }

    renderFilms = (films, includes) => {
        const renderedFilms = [];

        for(const film of films) {
            let heroImageUrl = '';
            const heroImageMedia = film.relationships.field_hero_image.data;
            if(heroImageMedia instanceof Object && heroImageMedia.hasOwnProperty("id")) {
                const heroImageFileId = includes.find( ({...attributes}) => attributes.id === heroImageMedia.id).relationships.field_media_image.data.id;
                heroImageUrl = includes.find( ({...attributes}) => attributes.id === heroImageFileId).links.large.href;
            }

            let url = '/node/' + film.attributes.drupal_internal__nid;
            if(film.attributes.path.alias !== "") {
                url = film.attributes.path.alias;
            }
            
            renderedFilms.push(<Card key={film.id} url={url} image={heroImageUrl} title={film.attributes.title} body={film.attributes.field_synopsis} />);
        }

        return renderedFilms;

    }

    handleFilterChange = (filterItem) => {
        let state = this.state;
        
        state.filters[filterItem.props.bundle] = {
            id: filterItem.props.value,
            label: filterItem.props.label
        };
        state.page = 0;
        state.hasMore = true;
        state.renderedFilms = [];
        state.links = {};

        this.setState(state);

        this.getFilms();
    }

    addFilterOptions = (options) => {
        if(this.state.filters.subject.id !== 'all') {
            options['filter[subject][path]'] = 'field_subjects.id';
            options['filter[subject][value]'] = this.state.filters.subject.id;
        }

        if(this.state.filters.country.id !== 'all') {
            options['filter[country][path]'] = 'field_countries.id';
            options['filter[country][value]'] = this.state.filters.country.id;
        }

        if(this.state.filters.language.id !== 'all') {
            options['filter[language][path]'] = 'field_languages.id';
            options['filter[language][value]'] = this.state.filters.language.id;
        }

        if(this.state.filters.genre.id !== 'all') {
            options['filter[genre][path]'] = 'field_genre.id';
            options['filter[genre][value]'] = this.state.filters.genre.id;
        }
        if(this.state.filters.person.id !== 'all') {
            options['filter[person][path]'] = 'field_directors.id';
            options['filter[person][value]'] = this.state.filters.person.id;
        }
        if(this.state.filters.festival_series.id !== 'all') {
            options['filter[festival_series][path]'] = 'field_festival_series.id';
            options['filter[festival_series][value]'] = this.state.filters.festival_series.id;
        }

        return options;
    }

}
