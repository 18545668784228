import React, { Component } from 'react';
import Text from '../../components/content/Text/Text';
import ItemList from '../../components/content/ItemList/ItemList';
import Body from '../../components/atoms/Body/Body';
import Title from '../../components/atoms/Title/Title';
import Site from '../../siteInfo';
import Slide from '../../components/ui/Slide/Slide';
import Slider from '../../components/ui/Slider/Slider';
import Meta from '../../components/atoms/Meta/Meta';

import './ContentPage.css'
import DrupalLink from "../../components/drupal/DrupalLink";

export default class ContentPage extends Component {

    state = {
        content: []
    };

    componentDidMount = async () => {

        document.title = Site.getPageTitleSlug() + ' | ' + this.props.entity.data.attributes.title;

    }
    
    render() {

        if(this.props.entity === null) {
            return (
                <div className="content-wrapper content-detail-page">
                    <div className="container page-content">
                        <Title placeholder />
                        <Body placeholder />
                        <Body placeholder />
                        <Body placeholder />
                    </div>
                </div>
            );
        } else {
            let content = this.getContentItems(this.props.entity.data.relationships.field_content.data);
            let sidebarContent = this.getContentItems(this.props.entity.data.relationships.field_sidebar_content.data);
            let slider = null;
            let heroes = [];

            if(this.props.entity.data.relationships.field_heroes.data.length > 0) {
                for(let heroRef of this.props.entity.data.relationships.field_heroes.data) {
                    const hero = this.props.api.lookupInclude(this.props.entity.included, heroRef.id);

                    let url = null;

                    const heroImageMedia = this.props.api.lookupInclude(this.props.entity.included, hero.relationships.field_hero_image.data.id);
                    const heroImageFile = this.props.api.lookupInclude(this.props.entity.included, heroImageMedia.relationships.field_media_image.data.id);
                    const heroImageUrl = heroImageFile.links.ajff_rec_slider.href;

                    if(hero.attributes.field_link !== null) {
                        url = DrupalLink.toUrl(hero.attributes.field_link);
                    }

                    heroes.push(<Slide key={hero.id} image={heroImageUrl} title={hero.attributes.field_title} link={url} />);

                    slider = <Slider>{heroes}</Slider>;
                }
            }

            const meta = <Meta data={this.props.entity.data.attributes.meta} api={ this.props.api } />

            if(sidebarContent.length > 0) {
                return (
                    <div className="content-wrapper content-detail-page">
                        {meta}

                        {slider}

                        <div className="container page-content">
                            <div className="row">
                                <div className="content col-sm-12 col-lg-8">
                                    <h1>{this.props.entity.data.attributes.title}</h1>

                                    {content}
                                </div>

                                <div className="sidebar col-sm-12 col-lg-4">
                                    {sidebarContent}
                                </div>
                            </div>

                        </div>

                    </div>
                );
            } else {
                return (
                    <div className="content-wrapper content-detail-page">
                        {meta}

                        {slider}

                        <div className="container page-content">

                            <div className="content">
                                <h1>{this.props.entity.data.attributes.title}</h1>

                                {content}

                            </div>

                        </div>

                    </div>
                );
            }
        }
    }

    getContentItems = (values) => {
        let items = [];

        for(let item of values) {
            const bundle = item.type.split("--").pop();

            if(bundle === 'item_list') {
                items.push(<ItemList key={item.id} entityId={item.id} api={ this.props.api } />);
            }

            if(bundle === 'text') {
                const include = this.props.api.lookupInclude(this.props.entity.included, item.id);
                items.push(<Text key={item.id} text={include.attributes.field_body.value} />);
            }
        }

        return items;

    }
}