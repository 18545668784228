import React, { Component } from 'react';

import './Title.css';

export default class Title extends Component {

    render() {
        if(this.props.placeholder) {
            return (
                <div className="title placeholder"></div>
            );
        } else {
            return (
                <h1>{this.props.text}</h1>
            );
        }

    }

}
